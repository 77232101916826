/**
 * @flow
 *
 * @format
 */

import moment from 'moment';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {Card, CardFooter, CardHeader, Container, Row, Table} from 'reactstrap';
import {Pagination} from 'src/components/Pagination';
import {useFilters} from 'src/contexts/FiltersContext';
import {type Review} from 'src/services/data/scoresAndReviews';

interface Props {
  reviews: Array<Review>;
}

const MissionLastReviews = ({reviews}: Props) => {
  const {t} = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentTableData, setCurrentTableData] = useState<any>();
  const {filterStartDate, filterEndDate} = useFilters();

  React.useEffect(() => {
    if (reviews) {
      const firstPageIndex = (currentPage - 1) * 10;
      const lastPageIndex = firstPageIndex + 10;
      setCurrentTableData(
        Object.values(reviews)
          .filter((item: any) => {
            return (
              moment(parseInt(item.timestamp)).format('yyyy-MM-DD') >= filterStartDate &&
              moment(parseInt(item.timestamp)).format('yyyy-MM-DD') <= filterEndDate
            );
          })
          .sort((a, b) => b.timestamp - a.timestamp)
          .slice(firstPageIndex, lastPageIndex),
      );
    } else {
      setCurrentTableData({});
    }
  }, [reviews, currentPage, filterStartDate, filterEndDate]);

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">{t('screens.missionDetails.lastReviews.title')}</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                <tr>
                  <th scope="col">{t('screens.missionDetails.lastReviews.fields.date')}</th>
                  <th scope="col">{t('screens.missionDetails.lastReviews.fields.user')}</th>
                  <th scope="col">{t('screens.missionDetails.lastReviews.fields.note')}</th>
                  <th scope="col">{t('screens.missionDetails.lastReviews.fields.comment')}</th>
                </tr>
                </thead>
                {currentTableData &&
                  Object.values(currentTableData).map((review, r) => {
                    const timestamp = parseInt(review.timestamp);
                    return (
                      <tbody>
                      <tr key={r}>
                        <th scope="row">
                            <span className="mb-0 text-sm">
                              {timestamp ? moment(timestamp).format('DD/MM/YYYY HH:mm') : '-'}
                            </span>
                        </th>
                        <th scope="row">
                          <Link to={`/userDetails/${review.userId}`}>
                            {review.userName === 'atlantideAgent' ? 'Tisseur EVA' : review.userName}
                          </Link>
                        </th>
                        <th scope="row">{review.note}</th>
                        <th scope="row">{review.comment ?? '-'}</th>
                      </tr>
                      </tbody>
                    );
                  })}
              </Table>
              {!currentTableData || !Object.keys(currentTableData).length ? (
                <div className="mt-3 mx-auto">
                  <p>{t('screens.missionDetails.lastReviews.noReviews')}</p>
                </div>
              ) : null}
              {Object.values(reviews).filter((item: any) => {
                return (
                  moment(parseInt(item.timestamp)).format('yyyy-MM-DD') >= filterStartDate &&
                  moment(parseInt(item.timestamp)).format('yyyy-MM-DD') <= filterEndDate
                );
              }).length > 10 && <CardFooter className="py-4">
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={
                    Object.values(reviews).filter((item: any) => {
                      return (
                        moment(parseInt(item.timestamp)).format('yyyy-MM-DD') >= filterStartDate &&
                        moment(parseInt(item.timestamp)).format('yyyy-MM-DD') <= filterEndDate
                      );
                    }).length
                  }
                  pageSize={10}
                  onPageChange={(page) => setCurrentPage(page)}
                  />
              </CardFooter>}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default MissionLastReviews;
