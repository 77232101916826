/**
 * @flow
 *
 * @format
 */

import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { NavLink as NavLinkRRD } from 'react-router-dom';
import {
  Button,
  Col,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { routes } from 'src/constants/routes';
import { useAuth } from 'src/contexts/AuthContext';
import { useFilters } from 'src/contexts/FiltersContext';
import { checkLastUpdateDate } from 'src/services/data/fetcher';
import { useSWRConfig } from 'swr';

const Sidebar = () => {
  const { t } = useTranslation();
  const [collapseOpen, setCollapseOpen] = useState<boolean>(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [displaySaveButton, setDisplaySaveButton] = useState<boolean>(false);
  const { logout } = useAuth();
  const history = useHistory();
  const { mutate } = useSWRConfig();
  const { filterStartDate, filterEndDate, setDates, setDefaultDates } = useFilters();

  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  const handleLogout = async () => {
    await logout();
    history.push('/login');
  };

  const handleReload = async () => {
    mutate('releases/scenarios/catalog/prod');
    mutate('results');
    mutate('scoresAndReviews');
    checkLastUpdateDate(true);
    setDefaultDates();
    history.go(0);
  };

  const handleSetDates = () => {
    setDates(startDate, endDate);
    setDisplaySaveButton(false);
  };

  return (
    <Navbar className="navbar-vertical fixed-left navbar-light bg-white" expand="md" id="sidenav-main">
      <Container fluid>
        {/* Toggler */}
        <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
          <span className="navbar-toggler-icon" />
        </button>
        {/* Brand */}
        <h1>{t('general.title')}</h1>
        <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <i className="ni ni-settings-gear-65" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">{t('general.welcome')}</h6>
              </DropdownItem>
              <DropdownItem href="" onClick={() => handleReload()}>
                <i className="fas fa-redo" />
                <span>{t('header.updateData')}</span>
              </DropdownItem>
              <DropdownItem onClick={() => handleLogout()}>
                <i className="ni ni-user-run" />
                <span>{t('header.settings')}</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              <Col xs="10">{t('general.title')}</Col>
              <Col className="collapse-close" xs="2" style={{ marginTop: '-5px' }}>
                <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Date filters */}
          <div>
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <FormGroup style={{ margin: 0 }}>
                <Label style={{ fontSize: 12 }}>{t('header.filters.startDate')}</Label>
                <Input
                  style={{ fontSize: 12, padding: 0 }}
                  type="date"
                  defaultValue={filterStartDate}
                  max={filterEndDate}
                  onChange={(e) => {
                    setDisplaySaveButton(true);
                    setStartDate(e.target.value);
                  }}
                />
              </FormGroup>
              <FormGroup style={{ margin: 0 }}>
                <Label style={{ fontSize: 12 }}>{t('header.filters.endDate')}</Label>
                <Input
                  style={{ fontSize: 12, padding: 0 }}
                  type="date"
                  defaultValue={filterEndDate}
                  max={moment().format('yyyy-MM-DD')}
                  onChange={(e) => {
                    setDisplaySaveButton(true);
                    setEndDate(e.target.value);
                  }}
                />
              </FormGroup>
            </div>
            {displaySaveButton && (
              <Button
                size="sm"
                className="mt-2 w-100 gradient-button"
                style={{ fontSize: 12, padding: '5px 10px' }}
                onClick={() => handleSetDates()}
              >
                {t('header.filters.apply')}
              </Button>
            )}
            <div className="text-center text-blue mt-2">
              <small
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  console.log('reseting filters');
                  setDefaultDates();
                }}
              >
                {t('header.filters.resetFilters')}
              </small>
            </div>
          </div>
          <hr style={{ marginBottom: '2vh', marginTop: '1vh' }} />
          {/* Navigation */}
          <Nav navbar>
            <NavItem>
              <NavLink to={routes.dashboard} tag={NavLinkRRD} onClick={closeCollapse}>
                <i className="ni ni-tv-2 text-primary" />
                {t('header.nav.dashboard')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to={routes.missionsList} tag={NavLinkRRD} onClick={closeCollapse}>
                <i className="ni ni-bullet-list-67 text-red" />
                {t('header.nav.missions')}
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default Sidebar;
