/**
 * @flow
 *
 * @format
 */

import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Nav,
  Navbar,
  UncontrolledDropdown,
} from 'reactstrap';
import { languageToFlag, switchLanguage } from 'src/assets/locales/i18n';
import { useAuth } from 'src/contexts/AuthContext';
import { useFilters } from 'src/contexts/FiltersContext';
import { checkLastUpdateDate } from 'src/services/data/fetcher';
import { useSWRConfig } from 'swr';

const Header = () => {
  const { t } = useTranslation();
  const { logout } = useAuth();
  const history = useHistory();
  const { mutate } = useSWRConfig();
  const [lastUpdate, setLastUpdate] = React.useState();
  const [setCurrentLocale] = React.useState();
  const { setDefaultDates } = useFilters();

  const handleLogout = async () => {
    await logout();
    history.push('/login');
  };

  const handleReload = async () => {
    mutate('releases/scenarios/catalog/prod');
    mutate('results');
    mutate('scoresAndReviews');
    checkLastUpdateDate(true);
    setDefaultDates();
    history.go(0);
  };

  const changeLocale = () => {
    const newLocale = switchLanguage();
    setCurrentLocale(newLocale);
  };

  React.useEffect(() => {
    const storageUpdate = JSON.parse(localStorage.getItem('lastUpdate'));
    setLastUpdate(storageUpdate);
  }, []);

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container className="align-items-center" fluid>
          <Link className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block text-ocean-orbiter" to="/">
            {t('general.title')}
          </Link>
          <small className="d-none d-lg-inline-block text-white font-italic">
            {t('header.lastUpdateDate')} {moment(lastUpdate).format('DD/MM/YYYY HH:mm')}
          </small>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="mb-0 text-sm font-weight-bold mr-2">{t('header.settings')}</span>
                  <i className="ni ni-settings-gear-65" />
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">{t('general.welcome')}</h6>
                </DropdownItem>
                <DropdownItem href="" onClick={() => changeLocale()}>
                  <img src={languageToFlag()} alt="" style={{ height: '20px', paddingRight: '15px' }} />
                  <span>{t('header.changeLanguage')}</span>
                </DropdownItem>
                <DropdownItem href="" onClick={() => handleReload()}>
                  <i className="fas fa-redo" />
                  <span>{t('header.updateData')}</span>
                </DropdownItem>
                <DropdownItem href="" onClick={() => handleLogout()}>
                  <i className="ni ni-user-run" />
                  <span>{t('header.logout')}</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
