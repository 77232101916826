/**
 * @format
 *
 * @flow
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardHeader, Col, Container, Row, Spinner, Table } from 'reactstrap';
import { LastGames, LastReviews, MostResolved, TopGames, UsersMostResolved } from 'src/components/Dashboard';
import UseDashboardData from 'src/hooks/UseDashboardData';

const DashboardScreen = () => {
  const { t } = useTranslation();
  const {
    lastTenResults,
    lastTenReviews,
    mostResolvedPuzzles,
    userWithMostResolved,
    fiveBestGames,
    fiveWorstGames,
    numberOfPlayer,
  } = UseDashboardData();

  return (
    <>
      <>
        <div className="header eva-gradient pb-8 pt-5 pt-md-8"></div>
      </>

      <Container fluid className="mt--7">
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="2">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">{t('screens.dashboard.numberOfPlayer.title')}</h3>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="text-center">
                <h1>{numberOfPlayer}</h1>
              </CardBody>
            </Card>
          </Col>
          <Col className="mb-5 mb-xl-0" xl="5">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">{t('screens.dashboard.mostResolvedPuzzles.title')}</h3>
                  </div>
                </Row>
              </CardHeader>
              {!mostResolvedPuzzles ? (
                <div
                  style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: '2vh',
                  }}
                >
                  <Spinner size="md">{t('general.loading')}</Spinner>
                </div>
              ) : (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">{t('screens.dashboard.mostResolvedPuzzles.fields.scenario')}</th>
                      <th scope="col">{t('screens.dashboard.mostResolvedPuzzles.fields.resolvesCount')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(mostResolvedPuzzles) &&
                      Object.values(mostResolvedPuzzles).map((item, r) => <MostResolved item={item} key={r} />)}
                  </tbody>
                </Table>
              )}
            </Card>
          </Col>
          <Col className="mb-5 mb-xl-0" xl="5">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">{t('screens.dashboard.userWithMostResolved.title')}</h3>
                  </div>
                </Row>
              </CardHeader>
              {!userWithMostResolved ? (
                <div
                  style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: '2vh',
                  }}
                >
                  <Spinner size="md">{t('general.loading')}</Spinner>
                </div>
              ) : (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">{t('screens.dashboard.userWithMostResolved.fields.user')}</th>
                      <th scope="col">{t('screens.dashboard.userWithMostResolved.fields.resolvesCount')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(userWithMostResolved) &&
                      Object.values(userWithMostResolved).map((item, r) => <UsersMostResolved item={item} key={r} />)}
                  </tbody>
                </Table>
              )}
            </Card>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="6">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">{t('screens.dashboard.lastTenGames.title')}</h3>
                  </div>
                </Row>
              </CardHeader>
              {!lastTenResults ? (
                <div
                  style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: '2vh',
                  }}
                >
                  <Spinner size="md">{t('general.loading')}</Spinner>
                </div>
              ) : (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">{t('screens.dashboard.lastTenGames.fields.date')}</th>
                      <th scope="col">{t('screens.dashboard.lastTenGames.fields.scenario')}</th>
                      <th scope="col">{t('screens.dashboard.lastTenGames.fields.user')}</th>
                      <th scope="col">{t('screens.dashboard.lastTenGames.fields.success')}</th>
                      <th scope="col">{t('screens.dashboard.lastTenGames.fields.progress')}</th>
                      <th scope="col">{t('screens.dashboard.lastTenGames.fields.details')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lastTenResults && lastTenResults.map((result, r) => <LastGames result={result} key={r} />)}
                  </tbody>
                </Table>
              )}
            </Card>
          </Col>
          <Col xl="6">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">{t('screens.dashboard.lastTenReviews.title')}</h3>
                  </div>
                </Row>
              </CardHeader>
              {!lastTenReviews ? (
                <div
                  style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: '2vh',
                  }}
                >
                  <Spinner size="md">{t('general.loading')}</Spinner>
                </div>
              ) : (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">{t('screens.dashboard.lastTenReviews.fields.date')}</th>
                      <th scope="col">{t('screens.dashboard.lastTenReviews.fields.scenario')}</th>
                      <th scope="col">{t('screens.dashboard.lastTenReviews.fields.user')}</th>
                      <th scope="col">{t('screens.dashboard.lastTenReviews.fields.note')}</th>
                      <th scope="col">{t('screens.dashboard.lastTenReviews.fields.comment')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lastTenReviews && lastTenReviews.map((review, r) => <LastReviews review={review} key={r} />)}
                  </tbody>
                </Table>
              )}
            </Card>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xl="6" className="mb-5 mb-xl-0">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">{t('screens.dashboard.fiveBestGames.title')}</h3>
                  </div>
                </Row>
              </CardHeader>
              {!fiveBestGames ? (
                <div
                  style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: '2vh',
                  }}
                >
                  <Spinner size="md">{t('general.loading')}</Spinner>
                </div>
              ) : (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">{t('screens.dashboard.fiveBestGames.fields.scenario')}</th>
                      <th scope="col">{t('screens.dashboard.fiveBestGames.fields.notesAverage')}</th>
                      <th scope="col">{t('screens.dashboard.fiveBestGames.fields.notesCount')}</th>
                    </tr>
                  </thead>
                  <tbody>{fiveBestGames && fiveBestGames.map((game, g) => <TopGames game={game} key={g} />)}</tbody>
                </Table>
              )}
            </Card>
          </Col>
          <Col xl="6">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">{t('screens.dashboard.fiveWorstGames.title')}</h3>
                  </div>
                </Row>
              </CardHeader>
              {!fiveWorstGames ? (
                <div
                  style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: '2vh',
                  }}
                >
                  <Spinner size="md">{t('general.loading')}</Spinner>
                </div>
              ) : (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">{t('screens.dashboard.fiveWorstGames.fields.scenario')}</th>
                      <th scope="col">{t('screens.dashboard.fiveWorstGames.fields.notesAverage')}</th>
                      <th scope="col">{t('screens.dashboard.fiveWorstGames.fields.notesCount')}</th>
                    </tr>
                  </thead>
                  <tbody>{fiveWorstGames && fiveWorstGames.map((game, g) => <TopGames game={game} key={g} />)}</tbody>
                </Table>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DashboardScreen;
