/**
 * @flow
 *
 * @format
 */

import moment from 'moment';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {Badge, Card, CardFooter, CardHeader, Container, Row, Table} from 'reactstrap';
import {Pagination} from 'src/components/Pagination';
import {useFilters} from 'src/contexts/FiltersContext';
import {type Score} from 'src/services/data/scoresAndReviews';

interface Props {
  results: Array<Score>;
}

const MissionLastScores = ({results}: Props) => {
  const {t} = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentTableData, setCurrentTableData] = useState<any>();
  const {filterStartDate, filterEndDate} = useFilters();

  React.useEffect(() => {
    if (results) {
      const firstPageIndex = (currentPage - 1) * 10;
      const lastPageIndex = firstPageIndex + 10;
      setCurrentTableData(
        Object.values(results)
          .filter((item: any) => {
            return (
              moment(parseInt(item.timestamp)).format('yyyy-MM-DD') >= filterStartDate &&
              moment(parseInt(item.timestamp)).format('yyyy-MM-DD') <= filterEndDate
            );
          })
          .sort((a, b) => b.timestamp - a.timestamp)
          .slice(firstPageIndex, lastPageIndex),
      );
    }
  }, [results, currentPage, filterStartDate, filterEndDate]);

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">{t('screens.missionDetails.lastScores.title')}</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                <tr>
                  <th scope="col">{t('screens.missionDetails.lastScores.fields.date')}</th>
                  <th scope="col">{t('screens.missionDetails.lastScores.fields.user')}</th>
                  <th scope="col">{t('screens.missionDetails.lastScores.fields.score')}</th>
                  <th scope="col">{t('screens.missionDetails.lastScores.fields.success')}</th>
                  <th scope="col">{t('screens.missionDetails.lastScores.fields.details')}</th>
                </tr>
                </thead>
                {currentTableData && Object.keys(currentTableData).length ? (
                  Object.values(currentTableData).map((score, s) => {
                    const timestamp = parseInt(score.timestamp);
                    return (
                      <tbody>
                      <tr key={s}>
                        <th scope="row">
                            <span className="mb-0 text-sm">
                              {timestamp ? moment(timestamp).format('DD/MM/YYYY HH:mm') : '-'}
                            </span>
                        </th>
                        <th scope="row">
                          <Link to={`/userDetails/${score.userId}`}>
                            {(score.userName === 'atlantideAgent' || !score.userName) ? 'Tisseur EVA' : score.userName}
                          </Link>
                        </th>
                        <th scope="row">{score.score}</th>
                        <th scope="row">
                          {score.isSuccess ? (
                            <Badge color="success" pill>
                              {t('screens.missionDetails.lastScores.fields.yes')}
                            </Badge>
                          ) : (
                            <Badge color="danger" pill>
                              {t('screens.missionDetails.lastScores.fields.no')}
                            </Badge>
                          )}
                        </th>
                        <th scope="row">
                          {t('screens.missionDetails.lastScores.fields.clues')} : {score.scoreDetails.cluesUsed},{' '}
                          {t('screens.missionDetails.lastScores.fields.duration')} :{' '}
                          {Math.round(score.scoreDetails.duration / 60000)}{' '}
                          {t('screens.missionDetails.lastScores.fields.min')},{' '}
                          {t('screens.missionDetails.lastScores.fields.gameOver')} : {score.scoreDetails.gameOvers},{' '}
                          {t('screens.missionDetails.lastScores.fields.wrongAnswers')} :{' '}
                          {score.scoreDetails.wrongAnswers}
                        </th>
                      </tr>
                      </tbody>
                    );
                  })
                ) : null}
              </Table>
              {!currentTableData || !Object.keys(currentTableData).length ? (
                <div className="mt-3 mx-auto">
                  <p>{t('screens.missionDetails.lastScores.noScores')}</p>
                </div>
              ) : null}
              {Object.values(results).filter((item: any) => {
                return (
                  moment(parseInt(item.timestamp)).format('yyyy-MM-DD') >= filterStartDate &&
                  moment(parseInt(item.timestamp)).format('yyyy-MM-DD') <= filterEndDate
                );
              }).length > 10 && <CardFooter className="py-4">
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={
                    Object.values(results).filter((item: any) => {
                      return (
                        moment(parseInt(item.timestamp)).format('yyyy-MM-DD') >= filterStartDate &&
                        moment(parseInt(item.timestamp)).format('yyyy-MM-DD') <= filterEndDate
                      );
                    }).length
                  }
                  pageSize={10}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </CardFooter>}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default MissionLastScores;
